// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "btn" ]

  connect() {
  }

  // this function add the parameter "ruid"
  // to the "destroy button" of an account_user (i.e member)
  // ruid: a user_id that will inherit the folders of the soon to be destroy member
  new_heir(event) {
    event.preventDefault()
    let new_user_id = event.target.value;
    let url = this.btnTarget.href
    url = url.substring(0, url.indexOf("ruid=") + 5);
    if (new_user_id == "") {
        this.btnTarget.style.display = "none"
        this.btnTarget.href = url
    } else {
        this.btnTarget.style.display = "block"
        this.btnTarget.href = url + new_user_id
    }
  }
  
}
