// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "fcselector", "field" ];

  connect() {
    this.change_folder_category_id();
  }

  change_folder_category_id() {  
    let folder_category_id = this.fcselectorTarget.value
    // hide/show the fields 
    this.fieldTargets.forEach((elt, i) => {
      elt.style.display = (folder_category_id == "FOLDER" ? "none" : "block");
    });
  }


}
