// a generic more... system for list of object
import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex';


export default class extends Controller {

    connect() {
        StimulusReflex.register(this)
    }

    set(event) {
        event.preventDefault()
        this.stimulate('LanguageReflex#set', event.target.value)
    }
}
