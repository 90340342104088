// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "tcdescriptors", "tcselector", "field", "icon" ];

  connect() {
    this.tcdescriptors = JSON.parse(this.tcdescriptorsTarget.getAttribute("tcdescriptors")); 
    this.change_task_category_id();
  }

  change_task_category_id() {  
    let task_category_id = this.tcselectorTarget.value
    console.log("change_task_category_id=")
    console.log(task_category_id)
    let fieldNamesToShow = this.tcdescriptors["tcid_" + task_category_id];
    // hide/show the fields 
    this.fieldTargets.forEach((elt, i) => {
      let fieldName = elt.getAttribute("name");
      elt.style.display = (fieldNamesToShow.indexOf(fieldName) == -1 ? "none" : "block");
    });
    // hide/show the icons 
    this.iconTargets.forEach((elt, i) => {
      elt.style.display = (elt.getAttribute("id") == task_category_id ? "block" : "none");
    });
  }


}
