// a generic more... system for list of object
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "list", "bt" ]

    connect() {
        this.pi = 1 // page index
        this.pp = 10 // nb record per page
        this.nb_record = parseInt(this.data.get("max"))
        this.show()
    }

    show() {
        fetch(this.data.get("url") + "?pi=" + this.pi + "&pp=" + this.pp)
            .then(response => response.text())
            .then(html => { this.listTarget.innerHTML = (this.pi == 1 ? "" : this.listTarget.innerHTML) + html; 
                            if (this.pi * this.pp < this.nb_record) {
                                this.pi = this.pi + 1;
                            } else {
                                // hide the button
                                this.btTarget.style.display = "none";
                            }                       
                          }
                )
    }
}
