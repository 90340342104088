// a generic more... system for list of object
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["input", "mainpanel", "deletepanel", "reassign"]

    select(event) {
        let newSelection = event.currentTarget
        let iname = newSelection.getAttribute("data-iname")
        this.inputTarget.value = iname
        let eltSelected = this.element.getElementsByClassName("icon-selected")[0]
        // remove current selection
        if (eltSelected) { eltSelected.classList.remove("icon-selected") };
        // add new selection
        newSelection.classList.add("icon-selected")
    }

    show_delete() {
        this.mainpanelTarget.style.display = "none";
        this.deletepanelTarget.style.display = "block";
    }

    delete(event) {
        let durl = event.currentTarget.getAttribute("data-durl") + "?reassign=" + this.reassignTarget.value;  
        window.location = durl      
    }

    cancel() {
        this.mainpanelTarget.style.display = "block";
        this.deletepanelTarget.style.display = "none";
    }
}
